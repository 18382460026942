.ds_background_color {
    background-color: #f9fafb !important;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
    background-color: #fff !important;
    color: #212B36 !important;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    background-image: none !important;
    overflow: hidden !important;
    box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12) !important;
    border-radius: 12px !important;
    position: relative !important;
    z-index: 0 !important;
}

.css-1qvr50w-MuiTypography-root {
    margin: 0 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 1.1rem !important;
    line-height: 1.334 !important;
    letter-spacing: 0em !important;
    display: block !important;
    font-weight: 600 !important;
    text-align: start !important;
}

.css-nrdprl-MuiTypography-root {
    margin: 0 !important;
    line-height: 1.5714285714285714 !important;
    font-size: 0.875rem !important;
    font-family: Public Sans, sans-serif !important;
    font-weight: 400 !important;
    color: #637381 !important;
    display: block !important;
    text-align: start !important;
}

.css-1ygcj2i-MuiTableCell-root {
    line-height: 1.5714285714285714 !important;
    font-size: 0.875rem !important;
    font-family: Public Sans, sans-serif !important;
    font-weight: 400 !important;
    display: table-cell !important;
    vertical-align: inherit !important;
    border-bottom: 1px solid rgba(241, 243, 244, 1) !important;
    text-align: left !important;
    padding: 25px !important;
    color: #212B36 !important;
}
.css-1ex1afd-MuiTableCell-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.43 !important;
    letter-spacing: 0.01071em !important;
    display: table-cell !important;
    vertical-align: inherit !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    text-align: left !important;
    padding: 25px !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

/* sidebar design css */
.ds_side_design {
    padding: .9rem !important;
    height: calc(100vh - 0rem);
    overflow: scroll;
    padding-top: 1rem;
}

.ds_list_item {
    background-color: #eff8f4;
    padding: .8rem !important;
    color: rgb(0, 167, 111);
    text-decoration: none !important;
    width: 100%;
    font-size: 15px !important;
    font-weight: 700;
    border-radius: 10px;
    margin-bottom: 7px;
}

.ds_list_item:hover {
    color: rgb(0, 167, 111);
}

.ds_list_item_non_active {
    padding: .8rem !important;
    color: #637481;
    text-decoration: none !important;
    width: 100%;
    font-size: 15px !important;
    font-weight: 700;
    border-radius: 10px;
    margin-bottom: 7px;
}

.ds_list_item_non_active:hover {
    background-color: #e9ebee !important;
    color: #637481;
}

.ds_side_border {
    border-right: 1px dashed #e0e4e8;
}

/* end sidebar design css */

/* header design css*/
.dh_cog_design {
    width: 35px !important;
    height: 35px !important;
    padding-top: 4px !important;
    border-radius: 35px;
}

.dh_cog_design:hover {
    background-color: #e9ebee !important;
}

.yes-button {
    background: #1f285a;
    border: none;
    padding: 5px 12px 6px 12px;
    color: white;
    border-radius: 5px;
    margin-right: 8px;
    font-size: 13px;
    font-weight: 600;
}

.no-button {
    background: #f44336;
    border: none;
    padding: 5px 12px 6px 12px;
    color: white;
    border-radius: 5px;
    margin-right: 4px;
    font-size: 13px;
    font-weight: 600;
}

.modal-text-design {
    font-size: 20px !important;
    color: black;
    font-weight: 600 !important;
    margin-bottom: 1.3rem !important;
}